import React from 'react';
import Navbar from '../components/navBar';
import { faTwitter, faLinkedin, faGithub, faMedium } from '@fortawesome/free-brands-svg-icons';
import SocialLink from '../components/SocialLink';
import MostRecentProjects from '../components/MostRecentProjects';
import RecentArticles from '../components/RecentArticles';
import Footer from '../components/Footer';
import ContactWidget from '../components/ContactWidget';


function Homepage() {
  return (
    <div className="relative">
      <div 
        className="min-h-screen relative bg-cover bg-center bg-no-repeat before:content-[''] before:absolute before:inset-0 before:bg-gradient-to-br before:from-purple-900/40 before:via-gray-900/90 before:to-black/95"
        style={{
          backgroundImage: `url('/hero.png')`,
          backgroundAttachment: 'fixed',
          '@media (min-width: 768px)': {
            backgroundImage: `url('/hero_desktop.png')`
          }
        }}
      >
        <div className="relative z-10">
          <Navbar />
          <main className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col items-start justify-center min-h-[calc(100vh-5rem)] max-w-4xl">
              <h1 className="text-4xl sm:text-5xl md:text-7xl font-bold mb-6 text-white tracking-tight leading-tight">
                <span className="block">HEY, I'M</span>
                <span className="block bg-gradient-to-r from-purple-400 to-pink-500 bg-clip-text text-transparent">
                  OLAWALE FILANI
                </span>
              </h1>
              
              <p className="text-lg sm:text-xl text-gray-300 leading-relaxed mb-8 max-w-2xl backdrop-blur-sm bg-gray-900/30 p-6 rounded-lg">
                A Software Engineer with a passion for building impactful mobile and web applications.
                I specialize in integrating advanced AI models to create user-friendly solutions that help businesses meet their clients' expectations.
              </p>

              <div className="flex flex-col sm:flex-row gap-4">
                <button className="px-8 py-4 bg-gradient-to-r from-purple-600 to-pink-600 text-white rounded-lg font-semibold hover:from-purple-700 hover:to-pink-700 transition-all duration-300 transform hover:scale-105 shadow-lg uppercase tracking-wide">
                  Recent Projects
                </button>
                <button className="px-8 py-4 bg-transparent border-2 border-purple-500 text-purple-400 rounded-lg font-semibold hover:bg-purple-500/10 transition-all duration-300 transform hover:scale-105 uppercase tracking-wide">
                  Contact Me
                </button>
              </div>
            </div>
          </main>

          <div className="fixed left-6 top-1/2 -translate-y-1/2 hidden lg:flex flex-col space-y-7">
            <SocialLink href="#" icon={faTwitter} className="text-gray-300 hover:text-purple-400 transition-colors duration-300" />
            <SocialLink href="#" icon={faLinkedin} className="text-gray-300 hover:text-purple-400 transition-colors duration-300" />
            <SocialLink href="https://github.com/olawale021" icon={faGithub} className="text-gray-300 hover:text-purple-400 transition-colors duration-300" />
            <SocialLink href="#" icon={faMedium} className="text-gray-300 hover:text-purple-400 transition-colors duration-300" />
          </div>

          <div className="lg:hidden flex justify-center space-x-6 pb-8">
            <SocialLink href="#" icon={faTwitter} className="text-gray-300 hover:text-purple-400 transition-colors duration-300" />
            <SocialLink href="#" icon={faLinkedin} className="text-gray-300 hover:text-purple-400 transition-colors duration-300" />
            <SocialLink href="https://github.com/olawale021" icon={faGithub} className="text-gray-300 hover:text-purple-400 transition-colors duration-300" />
            <SocialLink href="#" icon={faMedium} className="text-gray-300 hover:text-purple-400 transition-colors duration-300" />
          </div>
        </div>
      </div>

      <MostRecentProjects />
      <RecentArticles />
      <ContactWidget />
      <Footer />
    </div>
  );
}

export default Homepage;