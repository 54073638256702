import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import './styles/navBar.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`fixed w-full top-0 z-50 transition-all duration-300 border-b border-gray-800 ${
      scrolled 
        ? 'bg-gray-900 shadow-lg' 
        : 'bg-gray-900/95'
    }`}>
      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 md:h-20">
          <div className="flex items-center">
            <img
              src="/logo.png"
              alt="OLAHUB Logo"
              className="w-auto h-8 md:h-12 hover:scale-105 transition-transform duration-300"
            />
          </div>
          
          <div className="hidden md:block">
            <ul className="flex space-x-4 lg:space-x-8">
              <NavItem href="/" text="HOME" />
              <NavItem href="/about" text="ABOUT" />
              <NavItem href="/services" text="SERVICES" />
              <NavItem href="/projects" text="PROJECTS" />
              <NavItem href="#articles" text="ARTICLES" />
              <NavItem href="#contact" text="CONTACT" />
              <NavItem href="#blog" text="PERSONAL BLOG" />
            </ul>
          </div>

          <div className="md:hidden relative">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="mobile-menu-button inline-flex items-center justify-center p-2 rounded-full bg-gray-800 text-gray-300 hover:text-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500 transition-all duration-300"
              aria-label="Toggle menu"
            >
              <FontAwesomeIcon
                icon={isMenuOpen ? faTimes : faBars}
                className={`w-5 h-5 transform transition-all duration-300 ${
                  isMenuOpen ? 'rotate-90 scale-110' : ''
                }`}
              />
            </button>

            {/* Mobile Menu Dropdown */}
            <div
              className={`absolute right-0 top-full mt-2 w-56 rounded-lg bg-gray-900 shadow-lg ring-1 ring-black ring-opacity-5 transition-all duration-300 ease-in-out transform origin-top-right ${
                isMenuOpen
                  ? 'opacity-100 scale-100'
                  : 'opacity-0 scale-95 pointer-events-none'
              }`}
            >
              <div className="py-2">
                {['HOME', 'ABOUT', 'SERVICES', 'PROJECTS', 'ARTICLES', 'CONTACT', 'PERSONAL BLOG'].map((text, index) => (
                  <MobileNavItem
                    key={text}
                    href={text === 'HOME' ? '/' : `/${text.toLowerCase().replace(' ', '-')}`}
                    text={text}
                    delay={index}
                    isMenuOpen={isMenuOpen}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

const NavItem = ({ href, text }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <li className="relative nav-item nav-item-slide-in">
      <a
        href={href}
        className="text-sm lg:text-base text-gray-100 hover:text-purple-400 transition-all duration-300 py-2 inline-block nav-item-glow font-medium"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {text}
        <div
          className={`absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-purple-500 to-pink-500 transform origin-left transition-transform duration-300 ${
            isHovered ? 'scale-x-100' : 'scale-x-0'
          }`}
        />
      </a>
    </li>
  );
};

const MobileNavItem = ({ href, text }) => (
  <a
    href={href}
    className="block px-4 py-2 text-sm text-gray-100 hover:text-purple-400 hover:bg-gray-800 transition-all duration-300"
  >
    {text}
  </a>
);

export default Navbar;