import React, { useEffect, useRef } from 'react';
import { 
  Code, 
  Mail, 
  UserSquare2, 
  Bot, 
  Palette,
  RefreshCw 
} from 'lucide-react';
import Navbar from '../components/navBar';
import Footer from '../components/Footer';
import ContactWidget from '../components/ContactWidget';

const ServiceCard = ({ title, description, icon: Icon, delay }) => {
  const cardRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          cardRef.current.classList.add('animate-fade-in');
          cardRef.current.style.opacity = 1;
          cardRef.current.style.transform = 'translateY(0)';
        }
      },
      {
        threshold: 0.1,
        rootMargin: '50px'
      }
    );

    if (cardRef.current) {
      cardRef.current.style.opacity = 0;
      cardRef.current.style.transform = 'translateY(20px)';
      cardRef.current.style.transition = `all 0.6s ease-out ${delay}ms`;
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, [delay]);

  return (
    <div 
      ref={cardRef}
      className="group bg-gray-800 rounded-xl p-8 border border-gray-700 hover:border-purple-500 transition-all duration-300 transform hover:scale-105"
    >
      <div className="mb-6 inline-block p-4 bg-purple-500/20 rounded-lg">
        <Icon className="w-8 h-8 text-purple-400" />
      </div>
      <h2 className="text-2xl font-bold mb-4 text-white group-hover:text-transparent group-hover:bg-gradient-to-r group-hover:from-purple-400 group-hover:to-pink-500 group-hover:bg-clip-text transition-all duration-300">
        {title}
      </h2>
      <p className="text-gray-300 leading-relaxed">
        {description}
      </p>
    </div>
  );
};

const Services = () => {
  const headerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          headerRef.current.classList.add('animate-fade-in');
          headerRef.current.style.opacity = 1;
          headerRef.current.style.transform = 'translateY(0)';
        }
      },
      {
        threshold: 0.1
      }
    );

    if (headerRef.current) {
      headerRef.current.style.opacity = 0;
      headerRef.current.style.transform = 'translateY(20px)';
      headerRef.current.style.transition = 'all 0.6s ease-out';
      observer.observe(headerRef.current);
    }

    return () => {
      if (headerRef.current) {
        observer.unobserve(headerRef.current);
      }
    };
  }, []);

  const services = [
    {
      title: "Custom Web Design",
      description: "We craft fully bespoke, responsive, and user-friendly websites tailored to your business needs. Our design process focuses on creating a seamless user experience that aligns with your brand and goals.",
      icon: Code
    },
    {
      title: "Website Revamping",
      description: "Transform your outdated website into a modern, high-performing digital asset. We'll enhance your site's UI/UX, improve performance, ensure mobile responsiveness, and implement the latest web technologies while preserving your brand identity.",
      icon: RefreshCw
    },
    {
      title: "Email Marketing",
      description: "Reach your customers directly in their inbox with personalized email campaigns. We'll help you create engaging content, build targeted lists, and track performance analytics to continuously refine and improve.",
      icon: Mail
    },
    {
      title: "Leads Generation",
      description: "Attract the right audience and convert them into valuable leads. Our lead generation strategies include content marketing, social media campaigns, and landing pages designed to capture potential clients.",
      icon: UserSquare2
    },
    {
      title: "AI Chatbot Assistance",
      description: "Enhance customer support and engagement with intelligent chatbot solutions. We integrate AI-driven chatbots that can handle FAQs, schedule appointments, and provide instant support, freeing up your team to focus on complex tasks.",
      icon: Bot
    },
    {
      title: "Business Branding",
      description: "Establish a memorable identity with cohesive branding. From logo design to brand guidelines, we ensure every customer touchpoint reflects the unique qualities that set your business apart.",
      icon: Palette
    }
  ];

  return (
    <div className="relative">
      <div 
        className="min-h-screen relative bg-cover bg-center bg-no-repeat before:content-[''] before:absolute before:inset-0 before:bg-gradient-to-br before:from-purple-900/40 before:via-gray-900/90 before:to-black/95"
        style={{
          backgroundImage: `url('/hero.png')`,
          backgroundAttachment: 'fixed',
          '@media (min-width: 768px)': {
            backgroundImage: `url('/hero_desktop.png')`
          }
        }}
      >
        <div className="relative z-10">
          <Navbar />
          <main className="container mx-auto px-4 sm:px-6 lg:px-8 py-20">
            <h1 
              ref={headerRef}
              className="text-4xl md:text-5xl font-bold text-center mb-16"
            >
              <span className="bg-gradient-to-r from-purple-400 to-pink-500 bg-clip-text text-transparent">
                Our Services
              </span>
            </h1>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-7xl mx-auto">
              {services.map((service, index) => (
                <ServiceCard
                  key={index}
                  title={service.title}
                  description={service.description}
                  icon={service.icon}
                  delay={index * 100} // Stagger the animations
                />
              ))}
            </div>
          </main>
        </div>
      </div>
      <ContactWidget />
      <Footer />
    </div>
  );
};

export default Services;