import React, { useState } from 'react';
import { MessageCircle, X, Mail, MessageCircleMore } from 'lucide-react';

const ContactWidget = () => {
  const [isOpen, setIsOpen] = useState(false);

  const contacts = [
    {
      name: 'WhatsApp',
      icon: MessageCircleMore,
      href: 'https://wa.me/YOUR_WHATSAPP_NUMBER',
      bgColor: 'bg-green-500',
      hoverBg: 'hover:bg-green-600'
    },
    {
      name: 'Email',
      icon: Mail,
      href: 'mailto:your.email@example.com',
      bgColor: 'bg-purple-500',
      hoverBg: 'hover:bg-purple-600'
    }
  ];

  return (
    <div className="fixed bottom-6 right-6 z-50">
      {/* Contact options */}
      <div className={`flex flex-col items-end space-y-4 mb-4 transition-all duration-300 transform ${
        isOpen ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10 pointer-events-none'
      }`}>
        {contacts.map((contact, index) => {
          const Icon = contact.icon;
          return (
            <a
              key={index}
              href={contact.href}
              target="_blank"
              rel="noopener noreferrer"
              className={`flex items-center space-x-2 px-4 py-2 rounded-lg ${contact.bgColor} ${contact.hoverBg} text-white transition-all duration-300 transform hover:scale-105 shadow-lg group`}
            >
              <span className="hidden md:inline">{contact.name}</span>
              <Icon className="w-5 h-5" />
            </a>
          );
        })}
      </div>

      {/* Toggle button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`p-4 rounded-full bg-gradient-to-r from-purple-600 to-pink-600 text-white shadow-lg transition-all duration-300 transform hover:scale-110 ${
          isOpen ? 'rotate-90' : ''
        }`}
      >
        {isOpen ? (
          <X className="w-6 h-6" />
        ) : (
          <MessageCircle className="w-6 h-6" />
        )}
      </button>
    </div>
  );
};

export default ContactWidget;