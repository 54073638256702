import React from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../components/navBar';
import Footer from '../components/Footer';
import ContactWidget from '../components/ContactWidget';

export default function ProjectDetail() {
    const { id } = useParams();

    // Fetch project details based on id
    // This is a placeholder for where you'd typically fetch data from an API
    const project = {
        id: id,
        title: `Project ${id}`,
        description: `Detailed description of Project ${id}`,
        // Add more project details as needed
    };

    return (
        <div className="min-h-screen flex flex-col">
        <Navbar />
        
        <main className="flex-grow container mx-auto px-4 py-8">
            <h1 className="text-4xl font-bold mb-4">{project.title}</h1>
            <p className="text-lg mb-6">{project.description}</p>
            {/* Add more project details here */}
        </main>
        <ContactWidget />
        <Footer />
        </div>
    );
}